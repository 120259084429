.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* START */

.gradient-background {
  background: linear-gradient(300deg,#333333,#4c4c4c,#1c3f44,#182021);
  background-size: 240% 240%;
  animation: gradient-animation 24s ease infinite;
}

.gradientMasterSet {
  background: linear-gradient(51deg,#272f3d,#191f29,#182130);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

.gradientSecondarySet {
  background: linear-gradient(51deg,#272f3d,#212b3c,#343f53);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

.bgDarkGradient4 {
  background: linear-gradient(300deg,#323232,#212121,#171717,#000000);
  background-size: 240% 240%;
  animation: gradient-animation 24s ease infinite;
}

.bgDarkGradient {
  background: linear-gradient(248deg,#252525,#040404,#1d1d1d);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gridLogo {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
}